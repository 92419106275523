function Ozgecmis() {
  return (
    <div>
      <h1>Hakkında &raquo; Özgeçmiş</h1>
      <p>Doğum Yeri: Ankara</p>
      <p>Doğum Yılı: 1990</p>
    </div>
  );
}

export default Ozgecmis;